import { useFormik } from "formik";
import React, { useState } from "react";
import Loader from "../Loader";
import { forgotPass } from "../../Schema";
import AdminListService from "../../services/admin-list.service";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import Logo from "./Includes/Logo";

export default function ForgotPass() {
  const [loading, setLoading] = useState(false);

  const form = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: forgotPass,
    onSubmit: async (values, action) => {
      try {
        setLoading(true);
        const response = await AdminListService.adminForgotPass(values);

        toast.success(response?.data?.response, {
          position: "bottom-center",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: false,
        });
        // setTimeout(() => {
        //   navigate("/admin-dashboard");
        // }, 1500);
        setLoading(false);
        action.resetForm();
      } catch (err) {
        console.log(err);
        setLoading(false);
        if (
          err?.response?.data?.error &&
          typeof err?.response?.data?.error === "string"
        ) {
          toast.error(err?.response?.data?.error, {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error("Something went wrong, try again", {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      }
    },
  });

  return (
    <div className="tracker-app">
      <div className="tracker-forms">
        <div className="blue-bg-form h-440px">
          <Logo />
        </div>
        <form
          className="white-bg-form h-440px"
          onSubmit={form.handleSubmit}
          noValidate
        >
          <div
            className={`form-field ${
              form.errors.username && form.touched.username
                ? "border-danger"
                : ""
            }`}
          >
            <label className="form-label">Email Address</label>

            <div className="input-group">
              <span className="input-group-text" id="basic-addon1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="#797a7a"
                  className="bi bi-envelope-at"
                  viewBox="0 0 16 16"
                >
                  <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2zm3.708 6.208L1 11.105V5.383zM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2z" />
                  <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648m-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z" />
                </svg>
              </span>
              <input
                type="email"
                className="form-control"
                placeholder="Email Address"
                onChange={form.handleChange}
                name="username"
                value={form.values.username || ""}
              />
            </div>
          </div>

          {form.errors.username && form.touched.username ? (
            <div className="voh-order-error">{form.errors.username}</div>
          ) : null}
          <button className="btn btn-primary" type="submit">
            Submit
          </button>
          <Link to="/admin">Login</Link>
        </form>
        <div className="clearfix"></div>
      </div>

      {loading && <Loader />}
    </div>
  );
}
